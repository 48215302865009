import React, { Component } from 'react'
import { connect } from 'react-redux';
import { setCompany } from 'store/actions/comp';

import { DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, } from "reactstrap";
import { Select } from 'semantic-ui-react';
import Parse from 'widget/parse'
import { devPhaseOpt } from 'widget/companyDef';

const hostname = (process.env.BROWSER && global && global.window && global.window.location.hostname) || '';

class ChgCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      typeId: '1',
      companies: []
    };
  }

  async componentDidMount() {
    const companies = await Parse.queryData('company')
    this.setState({ companies })
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value })
  }

  render() {
    const { setCompany } = this.props; //companyObj
    const { typeId, companies = [] } = this.state

    return (<>
      {hostname === 'localhost' ?
        <UncontrolledDropdown className="btn-rotate" nav>
          <DropdownToggle aria-haspopup={true} caret color="default" data-toggle="dropdown" id="navbarDropdownMenuLink2" nav    >
            <i className="nc-icon nc-tv-2" />
            <p>
              <span className="d-lg-none d-md-block">網站</span>
            </p>
          </DropdownToggle>
          <DropdownMenu aria-labelledby="navbarDropdownMenuLink2" className="dropdown-danger" right>
            <DropdownItem header><Select name='typeId' value={typeId} options={devPhaseOpt} onChange={this.handleChange} /></DropdownItem>
            {companies.filter(item => item.devPhaseId === typeId).map(({ name, objectId, subdomainName }) => (
              <DropdownItem key={objectId}
                onClick={e => {
                  e.preventDefault()
                  // changeCompany(objectId);
                  setCompany({ companyId: objectId });
                  // history.push('/admin')
                }}
              >[{subdomainName || '無'}] {name}</DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown> : null}
    </>);
  }
}

const mapState = state => ({
});

const mapDispatch = {
  setCompany,
};

export default connect(mapState, mapDispatch)(ChgCompany);
// export default AdminNavbar;
