import React from 'react'
import { withCookies } from 'react-cookie';
// import Parse from 'widget/parse'

import PageHeader from 'routes/webDesign/webcomponent/PageHeader';
import Common0 from 'routes/webDesign/webcomponent/Common0';
import AlbumTest from 'routes/webDesign/webcomponent/AlbumTest';
// import QuickTest from '../webDesign/webcomponent/QuickTest';
import Test from 'routes/webDesign/webcomponent/Test';
import FileDownload from 'routes/webDesign/webcomponent/FileDownload';
import Cooperation from 'routes/webDesign/webcomponent/Cooperation';
import RecordInquire from 'routes/webDesign/webcomponent/RecordInquire';
import TransactionRecord from 'routes/webDesign/webcomponent/TransactionRecord';
// import Toeic from '../webDesign/webcomponent/Toeic';
// import Toeic1 from '../webDesign/webcomponent/Toeic1';
import SectionCourse1 from 'routes/webDesign/webcomponent/SectionCourse1';
import SectionCourse2 from 'routes/webDesign/webcomponent/SectionCourse2';
import SectionSlider from 'routes/webDesign/webcomponent/SectionSlider';
import SectionYearPlan from 'routes/webDesign/webcomponent/SectionYearPlan';
import SectionHeader1 from 'routes/webDesign/webcomponent/SectionHeader1';
import SectionHeader2 from 'routes/webDesign/webcomponent/SectionHeader2';
import SectionHeader3 from 'routes/webDesign/webcomponent/SectionHeader3';
import SectionHeader4 from 'routes/webDesign/webcomponent/SectionHeader4';
import SectionHeader5 from 'routes/webDesign/webcomponent/SectionHeader5';
import SectionFeature1 from 'routes/webDesign/webcomponent/SectionFeature1';
import SectionFeature2 from 'routes/webDesign/webcomponent/SectionFeature2';
import SectionFeature3 from 'routes/webDesign/webcomponent/SectionFeature3';
import SectionFeature4 from 'routes/webDesign/webcomponent/SectionFeature4';
import SectionFeature5 from 'routes/webDesign/webcomponent/SectionFeature5';
import SectionBlog1 from 'routes/webDesign/webcomponent/SectionBlog1';
import SectionBlog2 from 'routes/webDesign/webcomponent/SectionBlog2';
// import SectionBlog3 from '../webDesign/webcomponent/SectionBlog3';
import SectionBlog4 from 'routes/webDesign/webcomponent/SectionBlog4';
import SectionBlog5 from 'routes/webDesign/webcomponent/SectionBlog5';
import SectionBlog6 from 'routes/webDesign/webcomponent/SectionBlog6';
import SectionTeam1 from 'routes/webDesign/webcomponent/SectionTeam1';
import SectionTeam2 from 'routes/webDesign/webcomponent/SectionTeam2';
import SectionTeam3 from 'routes/webDesign/webcomponent/SectionTeam3';
import SectionTeam4 from 'routes/webDesign/webcomponent/SectionTeam4';
import SectionTeam5 from 'routes/webDesign/webcomponent/SectionTeam5';
import SectionProject1 from 'routes/webDesign/webcomponent/SectionProject1';
import SectionProject2 from 'routes/webDesign/webcomponent/SectionProject2';
import SectionProject3 from 'routes/webDesign/webcomponent/SectionProject3';
import SectionProject4 from 'routes/webDesign/webcomponent/SectionProject4';
import SectionPricing1 from 'routes/webDesign/webcomponent/SectionPricing1';
import SectionPricing2 from 'routes/webDesign/webcomponent/SectionPricing2';
import SectionPricing3 from 'routes/webDesign/webcomponent/SectionPricing3';
import SectionPricing4 from 'routes/webDesign/webcomponent/SectionPricing4';
import SectionPricing5 from 'routes/webDesign/webcomponent/SectionPricing5';
import SectionTestimonials1 from 'routes/webDesign/webcomponent/SectionTestimonials1';
import SectionTestimonials2 from 'routes/webDesign/webcomponent/SectionTestimonials2';
import SectionTestimonials3 from 'routes/webDesign/webcomponent/SectionTestimonials3';
import SectionContactUs1 from 'routes/webDesign/webcomponent/SectionContactUs1';
import SectionContactUs2 from 'routes/webDesign/webcomponent/SectionContactUs2';
import SectionFooterAreas1 from 'routes/webDesign/webcomponent/SectionFooterAreas1';
import SectionFooterAreas2 from 'routes/webDesign/webcomponent/SectionFooterAreas2';
import SectionFooterAreas3 from 'routes/webDesign/webcomponent/SectionFooterAreas3';
import SectionFooterAreas4 from 'routes/webDesign/webcomponent/SectionFooterAreas4';
import SectionFooterAreas5 from 'routes/webDesign/webcomponent/SectionFooterAreas5';
import SectionFooterAreas6 from 'routes/webDesign/webcomponent/SectionFooterAreas6';
import SectionFooterAreas7 from 'routes/webDesign/webcomponent/SectionFooterAreas7';
import FloorMaps from 'routes/webDesign/webcomponent/FloorMaps';
import FooterBlack from 'routes/webDesign/webcomponent/FooterBlack';
import StartUp from 'routes/webDesign/webcomponent/StartUp';
import StartUp1 from 'routes/webDesign/webcomponent/StartUp1';
import StartUp2 from 'routes/webDesign/webcomponent/StartUp2';
import StartUp3 from 'routes/webDesign/webcomponent/StartUp3';
import StartUp4 from 'routes/webDesign/webcomponent/StartUp4';
import StartUp1Edit from 'routes/webDesign/webcomponent/StartUp1Edit';
import StartUpEdit from 'routes/webDesign/webcomponent/StartUpEdit';
import SectionTable from 'routes/webDesign/webcomponent/SectionTable';
import SectionOpendata from 'routes/webDesign/webcomponent/SectionOpendata';

import { transContext } from 'widget/multilang'

const WebContent = (props) => {
  const { cookies, langArr, form } = props;
  const { componentId = '' } = form;
  // const dbLang = cookies.get('dbLang') || 'name';

  // const text1 = transContext(langArr, dbLang, form.text1 || '');
  const extProps = { ...form, }
  // console.log(componentId, extProps);
  return (<>
    {componentId === 'PageHeader' && <PageHeader {...props} form={extProps} />}
    {componentId === 'Common0' && <Common0 {...props} form={extProps} />}
    {componentId === 'AlbumTest' && <AlbumTest {...props} form={extProps} />}
    {/* {componentId === 'QuickTest' && <QuickTest {...props} form={extProps} />} */}
    {componentId === 'Test' && <Test {...props} form={extProps} />}
    {componentId === 'FileDownload' && <FileDownload {...props} form={extProps} />}
    {componentId === 'Cooperation' && <Cooperation {...props} form={extProps} />}
    {componentId === 'RecordInquire' && <RecordInquire {...props} form={extProps} />}
    {componentId === 'TransactionRecord' && <TransactionRecord {...props} form={extProps} />}
    {componentId === 'Opendata' && <SectionOpendata {...props} form={extProps} />}
    {/* {componentId === 'Toeic' && <Toeic {...props} form={extProps} />}
    {componentId === 'Toeic1' && <Toeic1 {...props} form={extProps} />} */}
    {componentId === 'SectionCourse1' && <SectionCourse1 {...props} form={extProps} />}
    {componentId === 'SectionCourse2' && <SectionCourse2 {...props} form={extProps} />}
    {componentId === 'SectionSlider' && <SectionSlider {...props} form={extProps} />}
    {/* {componentId === 'SectionYearPlan' && <SectionYearPlan {...props} form={extProps} />} */}
    {componentId === 'Header1' && <SectionHeader1 {...props} form={extProps} />}
    {componentId === 'Header2' && <SectionHeader2 {...props} form={extProps} />}
    {componentId === 'Header3' && <SectionHeader3 {...props} form={extProps} />}
    {componentId === 'Header4' && <SectionHeader4 {...props} form={extProps} />}
    {componentId === 'Header5' && <SectionHeader5 {...props} form={extProps} />}
    {componentId === 'Feature1' && <SectionFeature1 {...props} form={extProps} />}
    {componentId === 'Feature2' && <SectionFeature2 {...props} form={extProps} />}
    {componentId === 'Feature3' && <SectionFeature3 {...props} form={extProps} />}
    {componentId === 'Feature4' && <SectionFeature4 {...props} form={extProps} />}
    {componentId === 'Feature5' && <SectionFeature5 {...props} form={extProps} />}
    {componentId === 'Blog1' && <SectionBlog1 {...props} form={extProps} />}
    {componentId === 'Blog2' && <SectionBlog2 {...props} form={extProps} />}
    {/* {componentId === 'Blog3' && <SectionBlog3 {...props} form={extProps} />} */}
    {componentId === 'Blog4' && <SectionBlog4 {...props} form={extProps} />}
    {componentId === 'Blog5' && <SectionBlog5 {...props} form={extProps} />}
    {componentId === 'Blog6' && <SectionBlog6 {...props} form={extProps} />}
    {componentId === 'Team1' && <SectionTeam1 {...props} form={extProps} />}
    {componentId === 'Team2' && <SectionTeam2 {...props} form={extProps} />}
    {componentId === 'Team3' && <SectionTeam3 {...props} form={extProps} />}
    {componentId === 'Team4' && <SectionTeam4 {...props} form={extProps} />}
    {componentId === 'Team5' && <SectionTeam5 {...props} form={extProps} />}
    {componentId === 'Project1' && <SectionProject1 {...props} form={extProps} />}
    {componentId === 'Project2' && <SectionProject2 {...props} form={extProps} />}
    {componentId === 'Project3' && <SectionProject3 {...props} form={extProps} />}
    {componentId === 'Project4' && <SectionProject4 {...props} form={extProps} />}
    {componentId === 'Pricing1' && <SectionPricing1 {...props} form={extProps} />}
    {componentId === 'Pricing2' && <SectionPricing2 {...props} form={extProps} />}
    {componentId === 'Pricing3' && <SectionPricing3 {...props} form={extProps} />}
    {componentId === 'Pricing4' && <SectionPricing4 {...props} form={extProps} />}
    {componentId === 'Pricing5' && <SectionPricing5 {...props} form={extProps} />}
    {componentId === 'Testimonials1' && <SectionTestimonials1 {...props} form={extProps} />}
    {componentId === 'Testimonials2' && <SectionTestimonials2 {...props} form={extProps} />}
    {componentId === 'Testimonials3' && <SectionTestimonials3 {...props} form={extProps} />}
    {componentId === 'ContactUs1' && <SectionContactUs1 {...props} form={extProps} />}
    {componentId === 'ContactUs2' && <SectionContactUs2 {...props} form={extProps} />}
    {componentId === 'FooterBlack' && <FooterBlack {...props} form={extProps} />}
    {componentId === 'Footer1' && <SectionFooterAreas1 {...props} form={extProps} />}
    {componentId === 'Footer2' && <SectionFooterAreas2 {...props} form={extProps} />}
    {componentId === 'Footer3' && <SectionFooterAreas3 {...props} form={extProps} />}
    {componentId === 'Footer4' && <SectionFooterAreas4 {...props} form={extProps} />}
    {componentId === 'Footer5' && <SectionFooterAreas5 {...props} form={extProps} />}
    {componentId === 'Footer6' && <SectionFooterAreas6 {...props} form={extProps} />}
    {componentId === 'Footer7' && <SectionFooterAreas7 {...props} form={extProps} />}
    {componentId === 'FloorMaps' && <FloorMaps {...props} form={extProps} />}
    {componentId === 'SectionTable' && <SectionTable {...props} form={extProps} />}
    {componentId === 'StartUp' && <StartUp {...props} form={extProps} />}
    {componentId === 'StartUp1' && <StartUp1 {...props} form={extProps} />}
    {componentId === 'StartUp2' && <StartUp2 {...props} form={extProps} />}
    {componentId === 'StartUp3' && <StartUp3 {...props} form={extProps} />}
    {componentId === 'StartUp4' && <StartUp4 {...props} form={extProps} />}
    {componentId === 'StartUp1Edit' && <StartUp1Edit {...props} form={extProps} />}
    {componentId === 'StartUpEdit' && <StartUpEdit {...props} form={extProps} />}
  </>)
}

export default withCookies(WebContent);
