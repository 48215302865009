/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row } from "reactstrap";

// core components

function FooterGray() {
  return (
    <>
      <footer className="footer footer-gray footer-white">
        <Container>
          <Row>
            <nav className="footer-nav">
              <ul>
                <li>
                  <a
                    href="https://infowin.com.tw"
                    target="_blank"
                    className="mr-1"
                  >
                    {/* Infowin Technology Co., Ltd. */}
                    資宸科技有限公司
                  </a>
                </li>
                {/* <li>
                  <a
                    href="https://infowin.com.tw/#blog"
                    target="_blank"
                    className="mr-1"
                  >
                    Blog
                  </a>
                </li> */}
                <li>
                  <a
                    // href="https://infowin.com.tw/#privacy"
                    // href="https://infowin.com.tw/#privacy"
                    href="#privacy"
                  // target="_blank"
                  >
                    隱私權政策
                  </a>
                </li>
              </ul>
            </nav>
            <div className="credits ml-auto">
              <span className="copyright">
                © {new Date().getFullYear()}
                , made with <i className="fa fa-heart heart" /> by Infowin Technology Co., Ltd.
              </span>
            </div>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default FooterGray;
