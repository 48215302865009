export const webtypeSel = {
  cloudoffice: '雲辦公',
  // restaurant: '餐廳',
  // makeup: '美妝美容',
  // clothes: '服飾',
  temple: '佛教',
  dao: '道教',
  // hanbury: '漢栢略',
  // publishing: '出版社',
  // pawnshop: '當舖',
  dorm: '套房出租',
  // autorepair: '修車廠',
  // healthy: '健康管理',
  // salesInventory: '銷貨庫存',
  // crm: '客戶關係管理',
  official: '官網',
  // onlinetest: '線上測驗',
  school: '教育機構',
  // streaming: '網路電視',
  // ziwei: '紫微斗數',
  // lbry: '影音平台',
  // develop: '開發用',
  // doubleC: 'doubleC',
};

export const webtypeArr = Object.keys(webtypeSel).map((key) => ({ id: key, text: webtypeSel[key] }));

export const rankArr = [
  { rankText: '老闆', btnColor: 'info', rankId: '1' },
  { rankText: '員工', btnColor: 'warning', rankId: '2' },
  { rankText: '客戶', btnColor: 'danger', rankId: '3' },
  { rankText: '訪客', btnColor: 'secondary', rankId: '4' },
];

const devPhaseArr = ['1 常用', '2 測試', '3 穩定', '4 暫存', '5 資宸', '6 其他']

let sel = {}
devPhaseArr.forEach((item, i) => sel = { ...sel, [i + 1]: item })
export const devPhaseSel = sel;

export const devPhaseOpt = devPhaseArr.map((item, i) => ({ key: `${i + 1}`, value: `${i + 1}`, text: item }))
// console.log(devPhaseSel, devPhaseOpt)

export const localeDict = {
  /* @intl-code-template '${lang}-${COUNTRY}': '${Name}', */
  'en-US': '🇺🇸 English',
  'zh-TW': '🇹🇼 正體中文',
  'ja-JP': '🇯🇵 日本語',
  // 'cs-CZ': 'Česky',
  /* @intl-code-template-end */
}
